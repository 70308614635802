import { Tag } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import { StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';

export const columns: QueryTableColumnProps = [
  {
    title: '日志ID',
    dataIndex: 'loginId',
    key: 'loginId',
    width: 180,
  },
  {
    title: '用户名称',
    dataIndex: 'loginName',
    key: 'loginName',
    width: 120,
  },
  {
    title: 'IP地址',
    dataIndex: 'ipSource',
    key: 'ipSource',
    width: 100,
  },
  {
    title: '登录地址',
    dataIndex: 'loginLocation',
    key: 'loginLocation',
    width: 150,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'green' : 'red'}>
          {status === StatusEnum.NORMAL ? '操作成功' : '操作失败'}
        </Tag>
      );
    },
  },
  {
    title: '失败原因',
    dataIndex: 'loginMessage',
    key: 'loginMessage',
    width: 200,
  },
  {
    title: '操作日期',
    dataIndex: 'loginTime',
    key: 'loginTime',
    width: 200,
    render: (loginTime: string) => {
      return dayjs(loginTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  ipSource: {
    type: 'string',
    title: 'IP地址',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入IP地址',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  loginLocation: {
    type: 'string',
    title: '登录地址',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入登录地址',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: [
        { value: StatusEnum.NORMAL, label: '操作成功' },
        { value: StatusEnum.STOP, label: '操作失败' },
      ],
    },
  },
  '[createTime, endTime]': {
    type: 'array',
    title: '操作时间',
    'x-component': 'DatePicker.RangePicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: ['开始时间', '结束时间'],
      format: 'YYYY-MM-DD',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};
