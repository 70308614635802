import { useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { Input, DatePicker, Select } from '@formily/antd-v5';
import { Button, Card, Modal, message } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { deleteLoginLog, getLoginLogList } from 'src/api/clients/system';
import { IGetLogsLoginListReq, ILoginLogItem } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import dayjs from 'dayjs';

const SystemLogLoginTableName = 'SystemLogLoginTableName';

export default function SystemNotice() {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation();
  const getRolesTableList = useMemoizedFn(async (params: IGetLogsLoginListReq) => {
    const { createTime, endTime } = params;
    const result = await getLoginLogList({
      ...params,
      createTime: createTime ? dayjs(createTime).unix() : undefined,
      endTime: endTime ? dayjs(endTime).endOf('day').unix() : undefined,
    });
    const data = result.rows.map((item) => {
      return {
        ...item,
        userAgent: item.userAgent ? JSON.parse(item.userAgent) : '',
      };
    });
    return {
      data,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteDataByIds = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定日志吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteLoginLog(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      SystemLogLoginTableName,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: ILoginLogItem) => {
          return {
            buttons: [
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteDataByIds([col.loginId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('menu.logs.login')}</div>
      <QueryTable.Provider name={SystemLogLoginTableName} requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, DatePicker }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="loginId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteDataByIds(selectedRowKeys as string[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
    </Card>
  );
}
